import { HttpClient, HttpErrorResponse, HttpEvent, HttpEventType, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, InjectionToken, Provider } from '@angular/core';
import { Router } from '@angular/router';
import { CredentialsService } from '@app/modules/authentication/credentials.service';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { catchError, map, Subject, takeUntil, throwError, timeout } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AlertService } from '../alert/alert.service';
import { TranslateService } from '@ngx-translate/core';
const timoutInMilliSeconds = 1000 * 60 * 6;
/**
 * Injection token to pass the base URL to the @ApiService .
 */
export const BASE_PREFIX_URL = new InjectionToken<string>('basePrefixUrl', {
  providedIn: 'root',
  factory: () => environment.apiUrl,
});

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  hubConnection: HubConnection | undefined;
  cancelEmitter: Subject<boolean> = new Subject();
  routeTrackerUrl = environment.routeTrackerUrl;
  constructor(
    @Inject(BASE_PREFIX_URL) private baseURL: string,
    private http: HttpClient,
    private alertSrvc: AlertService,
    private credentialSrvc: CredentialsService,
    private router: Router,
    public translate: TranslateService,
  ) {}

  fetch<T>(path: string, data = {}, options = {}) {
    let params = new HttpParams({
      fromObject: JSON.parse(JSON.stringify(data)),
    });
    return this.http.get<T>(path, { params, ...options});
  }

  upload(path: string, file: File, headers: HttpHeaders) {
    const req = new HttpRequest('PUT', path, file, {
      reportProgress: true,
      headers,
    });
    return this.http.request<Blob>(req).pipe(
      map((event: HttpEvent<any>) => this.getEventMessage(event, file)),
      takeUntil(this.cancelEmitter),
      catchError((error: HttpErrorResponse) => this.handleError(error))
    );
  }

  delete<T>(path: string, body = {}) {
    return this.http.delete<T>(this.baseURL + path, { body }).pipe(
      timeout(timoutInMilliSeconds),
      takeUntil(this.cancelEmitter),
      catchError((error: HttpErrorResponse) => this.handleError(error))
    );
  }

  put<T>(path: string, params = {}) {
    return this.http
      .put<T>(this.baseURL + path, params)
      .pipe(catchError((error: HttpErrorResponse) => this.handleError(error)));
  }

  post<T>(path: string, params = {}) {
    return this.http.post<T>(this.baseURL + path, params).pipe(
      timeout(timoutInMilliSeconds),
      takeUntil(this.cancelEmitter),
      catchError((error: HttpErrorResponse) => this.handleError(error))
    );
  }

  get<T>(path: string, data = {}) {
    let params = new HttpParams({
      fromObject: JSON.parse(JSON.stringify(data)),
    });
    return this.http.get<T>(this.baseURL + path, { params }).pipe(
      timeout(timoutInMilliSeconds),
      takeUntil(this.cancelEmitter),
      catchError((error: HttpErrorResponse) => this.handleError(error))
    );
  }

  cancelAllRequests() {
    this.cancelEmitter.next(true);
  }

  handleNoInternetConnection() {
    this.cancelAllRequests();

    this.translate.get('CONSTANTS.NO_INTERNET_CONNECTION').subscribe((translatedMessage: string) => {
      this.alertSrvc.showToast({
        severity: 'warn',
        summary: translatedMessage, 
      });
    });

  
  }

  handleError(error: HttpErrorResponse) {
    if (!navigator.onLine || error.status === 0) {
      this.handleNoInternetConnection();
    } else if (error.status === 401) {
      this.router.navigate(['']);
    } else if (error.status === 423) {
      this.router.navigate(['/auth/verify-email']);
    } else if(error.status === 400){
      try{
        const err = JSON.parse(error?.error?.error);
        console.error(
          `Backend returned code ${error?.status}, body was: `,
          error
        );
        this.alertSrvc.showToast({
          severity: 'error',
          summary: 'Failed!',
          detail: err.error,
        });
      }catch(err){
        console.error(
          `Backend returned code ${error?.status}, body was: `,
          error
        );
        this.alertSrvc.showToast({
          severity: 'error',
          summary: 'Failed!',
          detail: error?.error?.error,
        });
      }
    } else if (error.status !== 404) {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error?.status}, body was: `,
        error
      );
      this.alertSrvc.showToast({
        severity: 'error',
        summary: 'Failed!',
        detail: error?.error?.error,
      });
    }
    // Return an observable with a user-facing error message.
    return throwError(() => error);
  }

  /** Return distinct message for sent, upload progress, & response events */
  private getEventMessage(event: HttpEvent<any>, file: File) {
    switch (event.type) {
      case HttpEventType.Sent:
        return 0;
      case HttpEventType.UploadProgress:
        // Compute and show the % done:
        const percentDone = Math.round(
          (100 * event.loaded) / (event.total ?? 0)
        );
        return percentDone;

      case HttpEventType.Response:
        return 100;

      default:
        return `File "${file.name}" surprising upload event: ${event.type}.`;
    }
  }

  async connectToSignalR(): Promise<HubConnection> {
    const options: signalR.IHttpConnectionOptions = {
      accessTokenFactory: () => {
        return this.credentialSrvc.credentials?.token || '';
      },
      withCredentials: false,
    };
    this.hubConnection = new HubConnectionBuilder()
      .withUrl(environment.signalRUrl, options)
      .build();

    try {
      await this.hubConnection.start();
      return this.hubConnection;
    } catch (error: any) {
      return error;
    }
  }

  /**
   * Factory method to modify the @baseURL of the @ApiService
   * @param prefixUrl The prefix url to be change the @baseURL member of the @ApiService
   * @returns provider of @ApiService with modified @baseURL
   *
   */
  setBaseURL(prefixUrl: string): ApiService {
    const newInstance = new ApiService(
      prefixUrl,
      this.http,
      this.alertSrvc,
      this.credentialSrvc,
      this.router,
      this.translate
    );
    newInstance.baseURL = prefixUrl;
    return newInstance;
  }
}
