{
  "name": "vendors",
  "version": "2.5.3",
  "scripts": {
    "ng": "ng",
    "build": "npm run env -s && ng build --configuration production",
    "build-dev": "npm run env -s && ng build --configuration dev",
    "start": "npm run env -s && ng serve --configuration dev --port 4200",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "hmr": "ng serve --configuration hmr",
    "lint": "ng lint",
    "start:prod-blueline": "npm run env -s && ng serve --configuration production-blueline --port 4200",
    "build-prod-blueline": "npm run env -s && ng build --configuration production --configuration production-blueline",
    "start:dev-comet": "npm run env -s && ng serve --configuration dev-comet --port 4200",
    "build-dev-comet": "npm run env -s && ng build --configuration dev-comet",
    "start:prod-comet": "npm run env -s && ng serve --configuration prod-comet --port 4200",
    "build-prod-comet": "npm run env -s && ng build --configuration prod-comet",
    "start:dev-iot-squared": "npm run env -s && ng serve --configuration dev-iot-squared --port 4200",
    "build-dev-iot-squared": "npm run env -s && ng build --configuration dev-iot-squared --verbose",
    "start:demo": "set NODE_OPTIONS=--openssl-legacy-provider && npm run env -s && ng serve --configuration demo --port 4200",
    "build-demo": "npm run env -s && ng build --configuration demo --verbose"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.2.11",
    "@angular/cdk": "^18.2.12",
    "@angular/common": "^18.2.11",
    "@angular/compiler": "^18.2.11",
    "@angular/core": "^18.2.11",
    "@angular/forms": "^18.2.11",
    "@angular/platform-browser": "^18.2.11",
    "@angular/platform-browser-dynamic": "^18.2.11",
    "@angular/router": "^18.2.11",
    "@angular/service-worker": "^18.2.11",
    "@microsoft/signalr": "^8.0.7",
    "@ngx-translate/core": "^16.0.3",
    "@ngx-translate/http-loader": "^16.0.0",
    "@types/heremaps": "^3.1.14",
    "@types/numeral": "^2.0.5",
    "angularx-qrcode": "^18.0.2",
    "chart.js": "^3.7.0",
    "class-transformer": "^0.5.1",
    "file-saver": "^2.0.5",
    "google-libphonenumber": "^3.2.27",
    "lodash-es": "^4.17.21",
    "moment": "^2.30.1",
    "ng-otp-input": "^1.8.5",
    "numeral": "^2.0.6",
    "primeflex": "^3.3.1",
    "primeicons": "^7.0.0",
    "primeng": "^17.18.11",
    "progress-tracker": "^2.0.6",
    "quill": "^1.3.7",
    "reflect-metadata": "^0.2.2",
    "rxjs": "~7.4.0",
    "tslib": "^2.3.0",
    "web-animations-js": "^2.3.2",
    "xlsx": "^0.18.5",
    "zone.js": "~0.14.10"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.11",
    "@angular-eslint/builder": "^18.4.0",
    "@angular-eslint/eslint-plugin": "^18.4.0",
    "@angular-eslint/eslint-plugin-template": "^18.4.0",
    "@angular-eslint/schematics": "^18.4.0",
    "@angular-eslint/template-parser": "^18.4.0",
    "@angular/cli": "^18.2.11",
    "@angular/compiler-cli": "^18.2.11",
    "@angularclass/hmr": "^3.0.0",
    "@types/file-saver": "^2.0.7",
    "@types/google-libphonenumber": "^7.4.23",
    "@types/jasmine": "~3.10.0",
    "@types/lodash-es": "^4.17.6",
    "@types/node": "^12.20.50",
    "@types/webpack-env": "^1.16.3",
    "@typescript-eslint/eslint-plugin": "5.3.0",
    "@typescript-eslint/parser": "5.3.0",
    "argv": "^0.0.3",
    "date-fns": "^4.1.0",
    "eslint": "^8.5.0",
    "eslint-config-airbnb-base": "^15.0.0",
    "eslint-config-airbnb-typescript": "^16.1.0",
    "eslint-config-prettier": "^8.3.0",
    "eslint-plugin-import": "^2.25.3",
    "eslint-plugin-prettier": "^4.0.0",
    "jasmine-core": "~3.10.0",
    "karma": "~6.3.0",
    "karma-chrome-launcher": "~3.1.0",
    "karma-coverage": "~2.1.0",
    "karma-jasmine": "~4.0.0",
    "karma-jasmine-html-reporter": "~1.7.0",
    "prettier": "^2.5.1",
    "readline": "^1.3.0",
    "shelljs": "^0.8.5",
    "typescript": "~5.4.5"
  }
}
